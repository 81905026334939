<script>
import Preloader from "@/new-components/Preloader/Preloader.vue";
import appConfig from "@/app.config";
import {loginUser} from '@/utils/apis.js';

export default {
  page: {
    
    title: "Login",
    meta: [
      {
        name: "Login page",
        content: appConfig.login_descriptions,
      },
    ],
  },
  data() {
    return {
      loader:false,
      email: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  methods:{
    async tryToLogIn(e){
      e.preventDefault()
      this.tryingToLogIn=true
      const body = {email:this.email, password:this.password}
      const res = await loginUser(body);
      console.log("#$$@@", res);
      if (res.access) {
        localStorage.setItem('app_refresh_token', res.refresh)
        localStorage.setItem('app_access_token', res.access)
        this.$router.push('/pages/listings');
      }
      else{
        this.$toasted.error("Something went wrong", {
          duration: 5000,
          position: "top-center",
          theme: "bubble",
          action: {
            text: "Close",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
            },
          },
        });
      }
      this.tryingToLogIn=false
    }
  },
  mounted(){
    if(localStorage.getItem('app_refresh_token')){
      this.$router.push('/pages/listings');
    }
  },
  components: {
    Preloader
  }
};
</script>

<template>
  <div>
    <Preloader v-if="loader"/>
    <div class="home-btn d-none d-sm-block">
      <router-link to="/" class="text-dark">
        <i class="mdi mdi-home-variant h2"></i>
      </router-link>
    </div>
    <div class="account-pages my-5 pt-sm-5">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card">
              <div class="card-body p-4">
                <div class="text-center mt-2">
                  <h5 class="text-primary">Welcome Back !</h5>
                  <p class="text-muted">Sign in to continue to Rentnet.</p>
                </div>
                <div class="p-2 mt-4">
                  <b-alert
                    v-model="isAuthError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ authError }}</b-alert
                  >

                  <b-form>
                    <b-form-group
                      id="input-group-1"
                      class="mb-3"
                      label="Email"
                      label-for="input-1"
                    >
                      <b-form-input
                        id="input-1"
                        v-model="email"
                        type="text"
                        placeholder="Enter email"
          
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-2" class="mb-3">
                      <div class="float-end">
                        <router-link
                          to="/pages/forgetpassword"
                          class="text-muted"
                          >Forgot password?</router-link
                        >
                      </div>
                      <label for="input-2">Password</label>
                      <b-form-input
                        id="input-2"
                        v-model="password"
                        type="password"
                        placeholder="Enter password"
                        
                      ></b-form-input>
                    </b-form-group>
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="auth-remember-check"
                      />
                      <label class="form-check-label" for="auth-remember-check"
                        >Remember me</label
                      >
                    </div>
                    <div class="mt-3 text-end">
                      <b-button v-if="!tryingToLogIn" @click="tryToLogIn($event)" variant="primary" class="w-sm"
                        >Log In</b-button
                      >
                      <b-button v-if="tryingToLogIn" variant="secondary" class="w-sm"
                        >Logging in</b-button
                      >
                    </div>
                    <div class="mt-4 text-center">
                      <div class="signin-other-title">
                        <h5 class="font-size-14 mb-3 title">Sign in with</h5>
                      </div>

                      <ul class="list-inline">
                        <li class="list-inline-item">
                          <a
                            href="javascript:void()"
                            class="social-list-item bg-primary text-white border-primary"
                          >
                            <i class="mdi mdi-facebook"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="javascript:void()"
                            class="social-list-item bg-info text-white border-info"
                          >
                            <i class="mdi mdi-twitter"></i>
                          </a>
                        </li>
                        <li class="list-inline-item">
                          <a
                            href="javascript:void()"
                            class="social-list-item bg-danger text-white border-danger"
                          >
                            <i class="mdi mdi-google"></i>
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div class="mt-4 text-center">
                      <p class="mb-0">
                        Don't have an account ?
                        <router-link
                          to="/signup"
                          class="fw-medium text-primary"
                          >Signup now</router-link
                        >
                      </p>
                    </div>
                  </b-form>
                </div>
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>
            
            <!-- end row -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
